.menu-rov-container {
    max-width: 1024px;
    margin: 0 auto;
}

.menu-ordered {
    background: #ebffe4;
}

.menu-item .menu-item {
    background: white;
}

.menu-item.menu-day-2, .menu-item.menu-day-4 {
    background-color: #f0f0f0;
}

td.menu-itemid {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
}

.menu-obed .menu-rov {
    font-size: medium;
}

.menu-action {
    width: 120px;
}

.menu-description {
    width: 50%;
}

.menu-day-2.menu-obed.menu-itemid-- td.menu-date {
    font-size: larger;
}

.menu-date:first-letter {
    text-transform: uppercase;
}

.list-menu-itemId {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
}

.taxovia-alert {
    margin-top: 1em;
}
