.no-menu-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
}

.no-menu-rov-found {
    font-size: 20px;
    text-align: center;
    padding: 20px;
}

.ant-carousel .slick-slide {
    text-align: center;
    height: 450px;
    line-height: 450px;
    background: #364d79;
    overflow: hidden;
}
