.app-title {
    float: left;
  }
  
  .app-title a {
    text-decoration: none;
    line-height: 54px;
    font-size: 16px;
    display: inline-block;
    color: white;
    font-variant: small-caps;
  }

.app-title a::first-letter {
  font-size: 24px;
  font-variant: normal;
}

.app-title a:hover {
    text-decoration: none;
  }
  
  .app-header {
    position: fixed;
    width: 100%;
    box-shadow: 0 2px 8px #f0f1f2;
    z-index: 10;
    padding: 0;
  }

  .app-header-flex {
    display: flex;
    justify-content: space-between;
  }

  .app-name {
    min-width: 200px;
    justify-content: right;
  }

@media (max-width: 768px) {
  .app-title a {
    font-size: 20px;
  }

  .app-menu > li {
    padding: 0 15px;
  }

  .app-menu > li > a {
    padding: 0 15px;
    margin: 0 -15px;
  }
}
